import APIService from './APIService';

export default {
    getCompanies() {
        return new Promise((resolve, reject) => {
            APIService.apiCall().get('companies')
            .then(response => {
                const { data: companies } = response.data;
                resolve(companies);
            })
            .catch(error => reject(error));  
        });
    }
}