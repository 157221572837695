<template>
  <v-container fluid class="pt-5">
    <base-material-card
      color="primary"
      icon="mdi-clipboard-outline"
      inline
      class="px-5 py-4 ml-0"
      :key="userData.id"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
        {{ idUser == null ? 'Novo Usuário': 'Editar Usuário'}}  
        </div>
      </template>

      <v-row class="mx-auto ">
        <v-col cols="12" sm="12">
          <div class=" display-1 font-weight-normal mx-auto  my-6">
            Vamos começar preenchendo as informações básicas para o login
          </div>
        </v-col>
      </v-row>

      <v-row class="mx-auto ">
        <v-col cols="12" sm="4">
          <validation-provider
          v-slot="{ errors }"
          name="Senha"
          rules="required"
          >
            <v-text-field
              label="Nome"
              prepend-icon="mdi-badge-account"
              :readonly="idUser != null"
              v-model="userData.name"
              :disabled="idUser != null"
              :value="userData.name"
              :error-messages="errors"
            />
          </validation-provider>

        </v-col>
        <v-col cols="12" sm="4">
          <validation-provider
          v-slot="{ errors }"
          name="E-mail"
          rules="required|email"
          >
            <v-text-field
              label="Email"
              prepend-icon="mdi-card-account-mail"
              :readonly="idUser != null"
              v-model="userData.email"
              :disabled="idUser != null"
              :value="userData.email"
              :error-messages="errors"
            />
          </validation-provider>

        </v-col>
      </v-row>
      <v-row class="mx-auto ">
        <v-col 
          cols="12" 
          sm="4">
            <validation-provider
            v-slot="{ errors }"
            name="Login"
            rules="required"
            >
              <v-text-field
                id="usuario-login"
                label="Login"
                :readonly="tipoAutenticacao != 'database'"
                :disabled="tipoAutenticacao != 'database'"
                prepend-icon="mdi-account"
                v-model="userData.login"
                :error-messages="errors"
              />
            </validation-provider>

        </v-col>
        <v-col 
          cols="12" sm="4"
          v-if="userData.isAdmin">
          
          <validation-provider
            v-slot="{ errors }"
            name="Perfil"
            rules="required"
          >
              <v-text-field
                label="Perfil"
                :error-messages="errors"
                prepend-icon="mdi-account-settings"
                readonly
                disabled
                :value="userData.role.role"
              />
          </validation-provider>
        </v-col>
        <v-col 
          cols="12" sm="4"
          v-else
          >
            <validation-provider
            v-slot="{ errors }"
            name="Perfil"
            rules="required"
            >
              <v-select
                :items="rolesList"
                v-model="selectedRole"
                :error-messages="errors"
                prepend-icon="mdi-account-settings"
                label="Perfil"
              />  
            </validation-provider>
        </v-col> 
      </v-row>
      <v-row class="mx-auto">
     
      </v-row> 

    <v-row
    class="mx-auto">
      <v-col 
      v-if="tipoAutenticacao == 'database'" 

      cols="12" sm="4"
      >
      <validation-provider
          v-slot="{ errors }"
          name="Senha"
          rules="required"
        >
        <v-text-field
          v-model="userData.senha"
          :type="mostrarSenha ? 'text' : 'password'"
          label="Senha"
          prepend-icon="mdi-lock-outline"
          :append-icon="mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="mostrarSenha = !mostrarSenha"
          validate-on-blur
          :error-messages="errors"
        />
        
      </validation-provider>
      </v-col>
    </v-row>

      <v-row 
        v-if="userRoleIsValidador"
        class="mx-auto mt-2"
      >
        <v-col cols="12">
          <h2>Autorizações do Validador</h2>
        </v-col>

        <v-col cols="12">
          <v-expansion-panels accordion multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>Empresas </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col class="pb-0 pt-0" cols="12">
                    <v-checkbox 
                      v-model="selectAllCompanies"
                      label="Selecionar todos as empresas"
                      hide-details
                      class="mt-0"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col 
                    class="pb-0 pt-1" 
                    md="2" sm="4" cols="6" 
                    v-for="item in companies" 
                    :key="item.id"
                  >
                    <v-checkbox 
                      v-model="companiesSelected"
                      :label="item.name"
                      :value="item.id"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Temas Indicadores Comercial V2</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col class="pb-0 pt-0" cols="12">
                    <v-checkbox 
                      v-model="selectAllComercialV2Categories"
                      label="Selecionar todos os temas"
                      hide-details
                      class="mt-0"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col 
                    class="pb-0 pt-1" 
                    md="2" sm="4" cols="6" 
                    v-for="item in indicadoresFieldsCategories.fields_indicadores_comerciais_v2_categories" 
                    :key="item.id"
                  >
                    <v-checkbox 
                      v-model="comercialV2SelectedCategories"
                      :label="item.category"
                      :value="item.id"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Temas Tipos de Serviços</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col class="pb-0 pt-0" cols="12">
                    <v-checkbox 
                      v-model="selectAllTiposServicosCategories"
                      label="Selecionar todos os temas"
                      hide-details
                      class="mt-0"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col 
                    class="pb-0 pt-1" 
                    md="3" sm="6" cols="auto" 
                    v-for="item in indicadoresFieldsCategories.servicos_categories" 
                    :key="item.id"
                  >
                    <v-checkbox 
                      v-model="tiposServicosSelectedCategories"
                      :label="item.nome"
                      :value="item.id"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Temas Indicadores Subestações</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col class="pb-0 pt-0" cols="12">
                    <v-checkbox 
                      v-model="selectAllTecSubCategories"
                      label="Selecionar todos os temas"
                      hide-details
                      class="mt-0"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col 
                    class="pb-0 pt-1" 
                    md="3" sm="6" cols="auto" 
                    v-for="item in indicadoresFieldsCategories.fields_indicadores_subestacoes_categories" 
                    :key="item.id"
                  >
                    <v-checkbox 
                      v-model="tecSubSelectedCategories"
                      :label="item.category"
                      :value="item.id"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Temas Indicadores Linhas de Distribuição</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col class="pb-0 pt-0" cols="12">
                    <v-checkbox 
                      v-model="selectAllTecLdCategories"
                      label="Selecionar todos os temas"
                      hide-details
                      class="mt-0"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col 
                    class="pb-0 pt-1" 
                    md="3" sm="6" cols="auto" 
                    v-for="item in indicadoresFieldsCategories.fields_indicadores_linhas_distribuicao_categories" 
                    :key="item.id"
                  >
                    <v-checkbox 
                      v-model="tecLdSelectedCategories"
                      :label="item.category"
                      :value="item.id"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Temas Indicadores Alimentadores</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense>
                  <v-col class="pb-0 pt-0" cols="12">
                    <v-checkbox 
                      v-model="selectAllTecAlCategories"
                      label="Selecionar todos os temas"
                      hide-details
                      class="mt-0"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col 
                    class="pb-0 pt-1" 
                    md="3" sm="6" cols="auto" 
                    v-for="item in indicadoresFieldsCategories.fields_indicadores_alimentadores_categories" 
                    :key="item.id"
                  >
                    <v-checkbox 
                      v-model="tecAlSelectedCategories"
                      :label="item.category"
                      :value="item.id"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row 
      v-if="tipoAutenticacao == 'database'" 
      class="mx-auto">
        <v-col cols="12" sm="12">
          <div class=" display-1 font-weight-normal mb-6">
            Opcionalmente, preencha os demais dados do usuário
          </div>
        </v-col>
      </v-row>
    <v-row 
        v-if="tipoAutenticacao == 'database'" 
        class="mx-auto"
    >
      <v-col cols="12" sm="4">
        <v-select
          id="usuario-tipo"
          v-model="userData.tipo"
          name="tipo"
          label="Tipo do usuário"
          :items="['COLABORADOR', 'TERCEIRO']"
          class="mb-0 pb-0"
          prepend-icon="mdi-tag"
        />
      </v-col>
      <v-col       cols="12" sm="4">
        <v-text-field
          id="usuario-cargo"
          label="Cargo"
          prepend-icon="mdi-certificate"
          v-model="userData.cargo"
        />
      </v-col>
    </v-row>

    <v-row 
        v-if="tipoAutenticacao == 'database'" 
        class="mx-auto"
    >
      <v-col       cols="12" sm="4">
        <v-text-field
          id="usuario-diretoria"
          label="Diretoria"
          prepend-icon="mdi-account-tie"
          v-model="userData.diretoria"
        />
      </v-col>
      <v-col       cols="12" sm="4">
        <v-text-field
          id="usuario-area-executiva"
          label="Área Executiva"
          prepend-icon="mdi-tie"
          v-model="userData.areaExecutiva"
        />
      </v-col>
    </v-row>

    
    <v-row class="mx-auto">
      <v-col cols="12" >
          <v-btn
            color="success"
            min-width="100"
            style="float: right;"
            :disabled="!validForm"
            :loading="loading"
            @click="saveUserData()"
          >
            Salvar
          </v-btn>
        </v-col>
    </v-row>
      <v-row class="mx-auto">

      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>

import UsersService from '@/services/UsersService';
import AuthService from '@/services/AuthService';
import CompanyService from '@/services/CompanyService'

export default {
  name: "UsuariosEdit",
  data: () => ({
    loading: false,
    userData: {
      'name': '',
      'login': '',
      'email': '',
      'senha': ''
    },
    roles: [],
    selectedRole: null,
    indicadoresFieldsCategories: {},
    comercialV2SelectedCategories: [],
    tiposServicosSelectedCategories: [],
    tecSubSelectedCategories: [],
    tecLdSelectedCategories: [],
    tecAlSelectedCategories: [],
    companies: [],
    companiesSelected: [],
    selectAllComercialV2Categories: false,
    selectAllTiposServicosCategories: false,
    selectAllTecSubCategories: false,
    selectAllTecLdCategories: false,
    selectAllTecAlCategories: false,
    selectAllCompanies: false,
    mostrarSenha: false,
    tipoAutenticacao: null,
  }),
  computed: {
    rolesList() {
      return this.roles
        .map(role => {
          return {
            value: role.id,
            text: role.role
          };
        });
    },

    userRoleIsValidador() {
      return this.roles.find(role => role.role === 'Validador')?.id === this.selectedRole;
    },
    idUser(){
      return this.$route.params.id ?? null
    },
    validForm(){
      let validForm = this.selectedRole != null;
      if(this.tipoAutenticacao == 'database'){
        const { name, login, email } = this.userData;
        const senha = this.userData.senha != undefined ? this.userData.senha :'';

        // Validação de e-mail
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

        const isAdmin = this.roles.find(role => role.admin === true)?.id == this.$store.getters.getUser.role_id;

        validForm = validForm && (
          name.length &&
          login.length &&
          (senha.length || this.idUser != null) &&
          email.length &&
          regex.test(email) &&
          !this.userData.isAdmin ||
          (this.userData.id == this.$store.getters.getUserId && isAdmin)

        );
      }
      return validForm;
    }
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;

      Promise.all([
        this.getUserById(this.idUser),
        this.getRoles(),
        this.getIndicadoresFieldsCategories(),
        this.getTipoAutenticacao(),
        this.getCompanies()
      ])
        .catch(err => {
          console.error(err);
          this.$toast.error('Ocorreu um erro ao recuperar os dados.', '', { position: 'topRight' });
        })
        .finally(() => this.loading = false);
    },
    getUserById(userId) {
      if(userId != null){
        return UsersService.getUserById(userId)
          .then(res => {
            this.userData = res;
  
            this.selectedRole = this.userData.role.id; 
            
            this.comercialV2SelectedCategories = this.userData.fields_indicadores_comerciais_v2_categories.map(el => el.id);
            this.tiposServicosSelectedCategories = this.userData.servicos_categories.map(el => el.id);
            this.tecSubSelectedCategories = this.userData.fields_indicadores_subestacoes_categories.map(el => el.id);
            this.tecLdSelectedCategories = this.userData.fields_indicadores_linhas_distribuicao_categories.map(el => el.id);
            this.tecAlSelectedCategories = this.userData.fields_indicadores_alimentadores_categories.map(el => el.id);
			this.companiesSelected = this.userData.companiesAllowedToValidate;
          })
          .catch(err => {
            throw err;
          });
      }
    },
    getCompanies() {
      return CompanyService.getCompanies()
        .then(res => {
          this.companies = res;

          if(this.companiesSelected.length == 0){
            this.companiesSelected = this.companies.map(company => company.id);
          }

        })
        .catch(err => {
          throw err;
        });
    },
    getRoles() {
      return UsersService.getRoles()
        .then(res => {
          this.roles = res;
        })
        .catch(err => {
          throw err;
        });
    },
    getIndicadoresFieldsCategories() {
      return UsersService.getIndicadoresFieldsCategories(true)
        .then(res => {
          this.indicadoresFieldsCategories = res;
        })
        .catch(err => {
          throw err;
        });
    },
    saveUserData() {
      this.loading = true;

      const postData = {
        login: this.userData.login,
        senha: this.userData.senha,
        email: this.userData.email,
        nome: this.userData.name,
        tipo:this.userData.tipo,
        cargo:this.userData.cargo,
        diretoria:this.userData.diretoria,
        areaExecutiva:this.userData.areaExecutiva,
        role_id: this.selectedRole,
        role_name: this.roles.find((element) => element.id == this.selectedRole).role,
        fields_indicadores_comerciais_v2_categories: this.comercialV2SelectedCategories, 
        servicos_categories: this.tiposServicosSelectedCategories, 
        fields_indicadores_subestacoes_categories: this.tecSubSelectedCategories, 
        fields_indicadores_linhas_distribuicao_categories: this.tecLdSelectedCategories, 
        fields_indicadores_alimentadores_categories: this.tecAlSelectedCategories, 
        companiesAllowed: this.companiesSelected
      }
      let idUser = this.idUser ?? 0
      UsersService.saveUserData(idUser, postData)
        .then(res => {
          this.$toast.success('Usuário salvo com sucesso.', '', { position: 'topRight' });
        })
        .catch(err => {
          console.error(err);
          this.$toast.error('Ocorreu um erro ao salvar o usuário.', '', { position: 'topRight' });
        })
        .finally(() => { 
          this.loading = false;
          this.$router.push(`/administracao/usuarios`)
        });
    },
    async getTipoAutenticacao() {
      this.tipoAutenticacao = await AuthService.getTipoAutenticacao();
    },

  },
  watch: {
    selectAllComercialV2Categories() {
      this.comercialV2SelectedCategories = this.selectAllComercialV2Categories 
        ? this.indicadoresFieldsCategories.fields_indicadores_comerciais_v2_categories.map(category => category.id) 
        : [];
    },
    selectAllTiposServicosCategories() {
      this.tiposServicosSelectedCategories = this.selectAllTiposServicosCategories 
        ? this.indicadoresFieldsCategories.servicos_categories.map(category => category.id) 
        : [];
    },
    selectAllTecSubCategories() {
      this.tecSubSelectedCategories = this.selectAllTecSubCategories 
        ? this.indicadoresFieldsCategories.fields_indicadores_subestacoes_categories.map(category => category.id) 
        : [];
    },
    selectAllTecLdCategories() {
      this.tecLdSelectedCategories = this.selectAllTecLdCategories 
        ? this.indicadoresFieldsCategories.fields_indicadores_linhas_distribuicao_categories.map(category => category.id) 
        : [];
    },
    selectAllTecAlCategories() {
      this.tecAlSelectedCategories = this.selectAllTecAlCategories 
        ? this.indicadoresFieldsCategories.fields_indicadores_alimentadores_categories.map(category => category.id) 
        : [];
    },
    selectAllCompanies(){
      this.companiesSelected = this.selectAllCompanies 
        ? this.companies.map(company => company.id) 
        : [];
    },
    selectedRole(){
      if(this.companiesSelected.length == 0){
            this.companiesSelected = this.companies.map(company => company.id);
      }
    }
  }
}

</script>

<style>

</style>